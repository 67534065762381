<template>
  <div id="data-record-search-bar-wrapper">
    <div
      class="search-bar-container"
      v-for="(conditionItem, conditionIndex) in conditionState.conditionList"
      :key="'condition' + conditionIndex"
    >
      <span class="text" v-if="conditionIndex == 0">筛选：</span>
      <el-select
        v-else
        class="search-operation-select"
        v-model="conditionItem.operationValue"
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="operationItem in conditionState.operationOptionList"
          :key="operationItem.value"
          :label="operationItem.label"
          :value="operationItem.value"
        />
      </el-select>
      <el-select
        class="search-bar-child"
        v-model="conditionItem.variableValue"
        clearable
        placeholder="请选择变量"
        @change="(value) => handleSelectChange(value, conditionIndex)"
      >
        <el-option
          v-for="variableItem in dataRecordFormLabelList"
          :key="variableItem.fieldName"
          :label="variableItem.fieldLabel"
          :value="variableItem.fieldName"
        />
      </el-select>
      <el-select
        class="search-bar-child"
        v-model="conditionItem.relationValue"
        clearable
        placeholder="请选择关系"
      >
        <el-option
          v-for="opItem in conditionItem.opOption"
          :key="opItem.value"
          :label="opItem.label"
          :value="opItem.value"
          :disabled="opItem.disabled"
        />
      </el-select>
      <el-date-picker
        class="search-bar-child"
        v-model="conditionItem.keyWords"
        type="date"
        placeholder="请选择日期"
        size="small"
        v-if="conditionItem.type == DATE"
      ></el-date-picker>
      <el-input
        v-else-if="conditionItem.type == NUMBER"
        class="search-bar-child"
        v-model="conditionItem.keyWords"
        type="number"
        placeholder="请输入关键词"
        clearable
      />
      <el-input
        v-else
        class="search-bar-child"
        v-model="conditionItem.keyWords"
        placeholder="请输入关键词"
        clearable
      />
      <div class="option-btn-box">
        <span
          class="iconfont opertion-label icon-jiahao"
          @click="handleAddOneCondition(conditionIndex)"
        ></span>
        <span
          class="iconfont opertion-label icon-jianhao"
          v-show="conditionIndex != 0"
          @click="handleDeleteOneCondition(conditionIndex)"
        ></span>
      </div>
      <el-button
        v-if="conditionIndex == conditionState.conditionList.length - 1"
        type="primary"
        @click="handleClickGetDataRecordList"
      >
        查询
      </el-button>
      <el-button
        v-if="conditionIndex == conditionState.conditionList.length - 1"
        type="primary"
        @click="handleClickClear"
      >
        清空
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
import { conditionToMap, and, or } from "@/utils/validation.js";
import {
  NUMBER_OPERATION_RELATION_LIST,
  TEXT_OPERATION_RELATION_LIST,
  NUMBER,
  DATE
} from "@/constant/variable.js";
import Moment from "moment";
const emits = defineEmits(["submit"]);
const props = defineProps({
  dataRecordFormLabelList: Array
});
// 筛选条件列表
let conditionState = ref({
  // 筛选条件值
  conditionList: [
    {
      operationValue: "",
      variableValue: "",
      relationValue: "",
      keyWords: "",
      type: "",
      opOption: [] // 变量关系列表
    }
  ],
  // 运算关系列表
  operationOptionList: [
    {
      value: "$and",
      label: "并且"
    },
    {
      value: "$or",
      label: "或者"
    }
  ]
});
//选择变量后更新变量关系列表
function handleSelectChange(value, conditionIndex) {
  console.log("🚀 ~ handleSelectChange ~ value:", value);
  if (value) {
    let variableOptionList = props.dataRecordFormLabelList;
    let type = variableOptionList.find((item) => {
      return item.fieldName == value;
    }).type;
    conditionState.value.conditionList[conditionIndex].type = type;
    // 数值或日期
    if (NUMBER == type || DATE == type) {
      conditionState.value.conditionList[conditionIndex].opOption =
        NUMBER_OPERATION_RELATION_LIST;
    } else {
      // 其他数据类型
      conditionState.value.conditionList[conditionIndex].opOption =
        TEXT_OPERATION_RELATION_LIST;
    }
  } else {
    conditionState.value.conditionList[conditionIndex].opOption = [];
    conditionState.value.conditionList[conditionIndex].relationValue = "";
  }
}
// 转换筛选条件格式
function exchangeCondition() {
  let con = conditionState.value.conditionList;
  let temps = [];
  for (let i = 0; i < con.length; i++) {
    let keyWords =
      con[i].keyWords instanceof Date
        ? Moment(con[i].keyWords).format("YYYY-MM-DD")
        : con[i].keyWords;
    temps.push(
      conditionToMap(
        con[i].variableValue,
        con[i].relationValue,
        con[i].type,
        keyWords
      )
    );
  }
  let temp = temps[0];
  if (con.length > 1) {
    for (let i = 0; i < con.length; i++) {
      if (con[i].logic != "") {
        if (con[i].logic == "$and") {
          temp = and(temp, temps[i]);
        } else {
          temp = or(temp, temps[i]);
        }
      }
    }
  }
  return temp;
}
// 增加一个筛选条件
const handleAddOneCondition = (index) => {
  if (conditionState.value.conditionList.length < 5) {
    conditionState.value.conditionList.splice(index + 1, 0, {
      operationValue: "",
      variableValue: "",
      relationValue: "",
      keyWords: ""
    });
  } else {
    ElMessage.warning("最多添加5个条件");
  }
};
// 删除一个筛选条件
const handleDeleteOneCondition = (index) => {
  conditionState.value.conditionList.splice(index, 1);
};
// 获取筛选结果列表
const handleClickGetDataRecordList = () => {
  conditionState.value.conditionList.forEach((item, index) => {
    let condition = conditionState.value.conditionList[index];
    if (index > 0) {
      if (
        condition.variableValue == "" &&
        condition.relationValue == "" &&
        condition.keyWords == "" &&
        condition.operationValue == ""
      ) {
        emits("submit");
      } else {
        if (
          condition.variableValue == "" ||
          condition.relationValue == "" ||
          condition.keyWords == "" ||
          condition.operationValue == ""
        ) {
          ElMessage.warning("请将筛选条件补充完整");
        } else {
          emits("submit", JSON.stringify(exchangeCondition()));
        }
      }
    } else {
      if (
        condition.variableValue == "" &&
        condition.relationValue == "" &&
        condition.keyWords == ""
      ) {
        emits("submit");
      } else {
        if (
          condition.variableValue == "" ||
          condition.relationValue == "" ||
          condition.keyWords == ""
        ) {
          ElMessage.warning("请将筛选条件补充完整");
        } else {
          emits("submit", JSON.stringify(exchangeCondition()));
        }
      }
    }
  });
};
const handleClickClear = () => {
  conditionState.value.conditionList = [
    {
      operationValue: "",
      variableValue: "",
      relationValue: "",
      keyWords: "",
      type: "",
      opOption: [] // 变量关系列表
    }
  ];
  handleClickGetDataRecordList();
};
defineExpose({ exchangeCondition, conditionState });
</script>

<style lang="scss" scoped>
#data-record-search-bar-wrapper {
  $search-child-width: 120px;
  $search-operation-width: 80px;
  // margin-bottom: 40px;
  .search-bar-container {
    width: 800px;
    margin-bottom: 12px;
    .text {
      display: inline-block;
      width: $search-operation-width;
    }
    .search-bar-child {
      width: $search-child-width;
      margin-left: 16px;
    }
    .opertion-label {
      @include pointer();
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      margin-right: 10px;
      margin-top: 3px;
    }
    .search-operation-select {
      width: $search-operation-width;
    }
    .option-btn-box {
      display: inline-block;
      width: 60px;
      margin-left: 12px;
      span {
        display: inline;
      }
    }
    .el-icon-plus,
    .el-icon-minus {
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: 1px solid #bbb;
      background-color: #ddd;
      margin-right: 10px;
    }
  }
  :deep(.el-date-editor.el-input),
  :deep(.el-date-editor .el-input__wrapper) {
    width: $search-child-width;
    height: 32px;
    margin-left: 16px;
  }
}
</style>
